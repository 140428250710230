@import '../../assets/style/variables.module.scss';

.about {
  width: 100%;
  max-width: 1200px;
  margin: auto;

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;

    .socialMedia {
      display: flex;
      align-items: center;

      span {
        font-size: 22px;
        color: $--dark-blue;
      }

      .icon {
        width: 70px;
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 25px;

        svg {
          width: 40px;
          height: 40px;
        }

        .line {
          width: 3px;
          height: 100%;
          background: $--light-green;
        }
      }
    }
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;

    .headImage {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      h1 {
        position: absolute;
        bottom: 30px;
        font-size: 34px;
        font-weight: 600;
        color: $--dark-blue;
        background: #d1f2ef;
        padding: 20px 30px;
        text-align: center;
        line-height: 46px;
        letter-spacing: 2px;
        border: 2px solid $--dark-blue;
        border-radius: 60px;
      }

      img {
        width: 100%;
        height: 700px;
        object-fit: cover;
        object-position: center;
      }
    }

    .text {
      margin: 50px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $--dark-blue;

      h2 {
        font-size: 40px;
        text-align: center;
      }

      .paragraph {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        flex-direction: column;
        gap: 30px;

        p {
          width: 100%;
          line-height: 30px;
          font-size: 19.5px;
          text-align: justify;
          hyphens: auto;

          .space {
            margin: 20px 0;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .about {
    max-width: 900px;

    .content {
      .headImage {
        h1 {
          font-size: 26px;
          line-height: 35px;
        }
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .about {
    max-width: 90vw;

    .header {
      .socialMedia {
        span {
          display: none;
        }
      }
    }

    .content {
      .headImage {
        h1 {
          font-size: 18px;
          line-height: 28px;
        }
      }

      .text {
        .paragraph {
          flex-direction: column;

          p {
            width: 100%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .about {
    max-width: 90vw;

    .header {
      .socialMedia {
        span {
          display: none;
        }
      }
    }

    .content {
      .headImage {
        flex-direction: column;
        h1 {
          font-size: 18px;
          line-height: 28px;
          position: static;
          margin-top: 30px;
        }

        img {
          height: 300px;
        }
      }
    }
  }
}
