@import '../../assets/style/variables.module.scss';

.sacDokulmesiTedavileri {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper {
    width: 100%;
    max-width: $--max-width;
    display: flex;
    flex-direction: column;
    align-items: center;

    .headCard {
      height: 660px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      padding-left: 50px;
      padding-top: 35px;
      width: 100%;
      box-sizing: border-box;
      h1 {
        font-size: 60px;
        line-height: 70px;
        color: $--dark-blue;
      }

      h2 {
        font-size: 19px;
        color: $--dark-blue;
        max-width: 490px;
        line-height: 27px;
        font-weight: 500;
      }
    }

    .paragraphWrapper {
      background: #d0f4ee;
      padding: 20px;
      box-sizing: border-box;
      width: 100%;
      .paragraph {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        .column {
          width: 100%;

          p,
          h2 {
            color: $--dark-blue;
          }

          h2 {
            margin-bottom: 20px;
            font-weight: 700;
            font-size: 19.5px;
          }

          span {
            font-style: italic;
          }

          p {
            line-height: 30px;
            font-size: 19.5px;
            text-align: justify;
            hyphens: auto;

            .space {
              margin: 20px 0;
            }
          }
        }
      }
    }

    .paragraphWrapperWithImage {
      padding: 20px;
      box-sizing: border-box;
      width: 97%;
      margin-top: 30px;
      .paragraph {
        display: flex;
        justify-content: space-between;
        gap: 50px;

        .column {
          width: 50%;
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          p,
          h2 {
            color: $--dark-blue;
          }

          h2 {
            margin-bottom: 20px;
            font-weight: 700;
            font-size: 19.5px;
          }

          span {
            font-style: italic;
          }

          p {
            line-height: 30px;
            font-size: 19.5px;
            text-align: justify;
            hyphens: auto;
            font-weight: 400;
          }

          img {
            width: calc(100% + 40px);
            height: 100%;
            object-fit: cover;
            object-position: center;
            margin-left: -20px;
            position: relative;
          }

          .backCover {
            width: calc(100% + 40px);
            height: 100%;
            right: -50px;
            margin-top: -30px;
            position: absolute;
            background: #ebfcf9;
            z-index: -1;
          }
        }
      }
    }

    .rowReverse {
      .paragraph {
        flex-direction: row-reverse;

        .column {
          img {
            height: 400px;
          }
          .backCover {
            left: -50px;
            max-height: 400px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .sacDokulmesiTedavileri {
    overflow-x: hidden;
    .wrapper {
      .headCard {
        padding: 50px;
        height: 400px;
        h1 {
          text-align: center;
        }

        h2 {
          text-align: center;
        }
      }

      .paragraphWrapper {
        padding: 20px 20px;
        width: 90%;

        .paragraph {
          flex-direction: column;
          width: 100%;

          .column {
            width: 100%;
          }
        }
      }

      .paragraphWrapperWithImage {
        padding: 20px 20px;
        width: 90%;

        .paragraph {
          flex-direction: column;
          width: 100%;

          .column {
            width: 100%;
          }
        }
      }

      .rowReverse {
        .paragraph {
          .column {
            img {
              height: 250px;
            }
          }
        }
      }
    }
  }
}
