@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy-Semibold.woff2') format('woff2'),
    url('../fonts/Gilroy-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy-LightItalic.woff2') format('woff2'),
    url('../fonts/Gilroy-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy-Extrabold.woff2') format('woff2'),
    url('../fonts/Gilroy-Extrabold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy-ExtraboldItalic.woff2') format('woff2'),
    url('../fonts/Gilroy-ExtraboldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy-Regular.woff2') format('woff2'),
    url('../fonts/Gilroy-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

$--light-green: #00b2aa;
$--dark-blue: #426586;
$--light-blue: #d1f2ef;

$--max-width: 1200px;

body {
  font-family: 'Gilroy', 'sans-serif';
}

textarea,
input {
  font-family: 'Gilroy', 'sans-serif';

  font-weight: 300 !important;
}

p,
h1,
h2,
h3,
h4,
h5,
a {
  &::selection {
    color: #426586;
    background: #d1f2ef;
  }
}
