@import '../../assets/style/variables.module.scss';

.footerForm {
  width: 100%;
  max-width: $--max-width;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;

  .logo {
    width: 40%;
    background: #ebfcf9;
    padding: 80px;
    box-sizing: border-box;

    h4 {
      color: $--dark-blue;
      font-weight: 600;
      border-top: 1px solid $--dark-blue;
      padding-top: 20px;
      margin-top: 40px;
      line-height: 25px;
    }
  }

  .formWrapper {
    width: 60%;
    background: #d0f4ee;
    padding-left: 80px;
    padding-top: 45px;
    padding-right: 45px;
    box-sizing: border-box;
    padding-bottom: 90px;

    h2 {
      font-size: 28px;
      line-height: 33px;
      color: $--dark-blue;
    }

    .form {
      margin-top: 40px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 15px;

      .input,
      .textarea {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        h5 {
          min-width: 110px;
          font-size: 19px;
          color: $--dark-blue;
          font-weight: 600;
        }

        input {
          width: 100%;
          height: 30px;
          border-radius: 5px;
          padding-left: 10px;
          box-sizing: border-box;
        }

        textarea {
          width: 100%;
          min-height: 80px;
          border-radius: 5px;
          resize: none;
          padding-left: 10px;
          padding-top: 10px;
          box-sizing: border-box;
        }
      }

      .checkbox {
        display: flex;

        span {
          margin-left: 10px;
          font-size: 14px;
          color: $--dark-blue;
          font-weight: 600;
        }
      }

      .submitWrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        box-sizing: border-box;
        padding-left: 110px;

        .submit {
          font-size: 16px;
          font-weight: 600;
          color: $--dark-blue;
          background: white;
          border-radius: 5px;
          box-shadow: 2px 2px #426586;
          padding: 6px 10px;
          cursor: pointer;
        }
      }
    }
  }
}

.error {
  color: rgb(238, 98, 98);
}

@media only screen and (max-width: 900px) {
  .footerForm {
    flex-direction: column-reverse;

    .logo {
      width: 100%;
    }

    .formWrapper {
      width: 100%;
      padding: 50px;

      .form {
        .input,
        .textarea {
          flex-direction: column;
          align-items: flex-start;
          gap: 10px;
        }
      }
    }
  }
}
