@import '../../assets/style/variables.module.scss';

.fueSacEkimi {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper {
    width: 100%;
    max-width: $--max-width;

    .subTitle {
      font-size: 19px;
      line-height: 27px;
      color: $--dark-blue;
      text-align: center;
      margin: 30px 0;
      font-style: italic;
    }

    .card {
      width: 100%;
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: right;
      min-height: 780px;
      box-sizing: border-box;
      position: relative;

      img {
        width: 100%;
      }

      h1 {
        font-size: 60px;
        font-weight: 700;
        color: $--dark-blue;
        margin-top: 20px;

        span {
          font-style: italic;
          font-weight: 300;
        }
      }

      p {
        bottom: 0;
        font-size: 19px;
        line-height: 27px;
        left: 60px;
        background-color: #ebfcf9;
        padding: 20px;
        box-sizing: border-box;
        font-weight: 500;
        color: $--dark-blue;
        width: 100%;
        .space {
          margin: 20px 0;
        }
      }
    }

    .listCardWrapper {
      margin-top: 30px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h3 {
        font-size: 30.5px;
        color: $--dark-blue;
        border: 1px solid $--dark-blue;
        background: #ebfcf9;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .listCard {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        ul {
          width: 50%;
          height: 100%;
          min-height: 145px;
          color: $--dark-blue;
          border: 1px solid $--dark-blue;
          background: #ebfcf9;
          padding: 15px;
          display: flex;
          flex-direction: column;
          margin-top: -1px;
          box-sizing: border-box;

          li {
            font-weight: 500;
            line-height: 27px;
            margin-left: 20px;

            &::before {
              content: '\2022';
              color: $--dark-blue;
              font-weight: bold;
              display: inline-block;
              font-size: 17px;
              width: 16px;
              margin-left: -1em;
            }
          }
        }
      }
    }

    .content {
      display: flex;
      width: 100%;
      gap: 50px;
      align-items: center;

      .column {
        width: 50%;

        h3 {
          font-size: 40px;
          padding: 30px 0;
          display: flex;
          align-items: center;
          justify-content: center;
          background: $--light-blue;
          font-weight: 600;
          color: $--dark-blue;
          text-align: center;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }

        .imageFirst {
          height: 400px;
        }

        .imageSecond {
          height: 350px;
          width: 100%;
        }

        p {
          line-height: 27px;
          font-size: 19px;
          color: $--dark-blue;
          margin-left: 45px;
          text-align: justify;
          hyphens: auto;
        }

        ul {
          width: 100%;
          color: $--dark-blue;
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
          padding-left: 27px;
          margin-bottom: 27px;
          margin-top: 20px;

          li {
            font-weight: 500;
            line-height: 27px;
            margin-left: 20px;
            font-size: 19px;

            &::before {
              content: '\2022';
              color: $--dark-blue;
              font-weight: bold;
              display: inline-block;
              font-size: 17px;
              width: 16px;
              margin-left: -1em;
            }
          }
        }

        h5 {
          font-size: 19px;
          color: $--dark-blue;
          font-weight: 700;
          margin-left: 47px;
          margin-bottom: -10px;
        }
      }
    }

    .contentReverse {
      flex-direction: row-reverse;
      margin-top: 50px;
    }

    .margin {
      margin-top: 50px;
    }
  }
}

@media only screen and (max-width: 900px) {
  .fueSacEkimi {
    .wrapper {
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;

      .card {
        background-position: top;
        background-size: 100% auto;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
        }

        h1 {
          text-align: center;
          font-size: 42px;
          line-height: 45px;
        }
        p {
          position: relative;
          left: unset;
          width: 90%;
          margin-top: 20px;
        }
      }

      .listCardWrapper {
        width: 90%;
        h3 {
          text-align: center;
        }
        .listCard {
          width: 100%;
          display: flex;
          align-items: unset;
        }
      }

      .subTitle {
        width: 90%;
      }
      .content {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        .column {
          width: 90%;
          flex-direction: column;

          .imageFirst {
            height: unset;
          }

          .imageSecond {
            height: unset;
            width: 100%;
          }

          h3 {
            text-align: center;
          }

          ul {
            padding-left: 0;
          }

          p {
            margin-left: 0;
          }
        }
      }

      .contentReverse {
        flex-direction: column-reverse;
      }

      .mobile {
        flex-direction: column;
      }
    }
  }
}
