@import '../../assets/style/variables.module.scss';

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
  margin-top: 30px;

  .wrapper {
    width: 100%;
    max-width: $--max-width;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      height: 130px;

      img {
        height: 100%;
      }
    }

    .contentWrapper {
      width: 75%;
      display: flex;
      flex-direction: column;

      .top {
        display: flex;
        justify-content: space-between;

        .socialMedia {
          display: flex;
          align-items: center;

          a {
            padding: 0 5px;
            border-right: 1px solid #bdbdbd;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              path {
                fill: #878787;
              }
            }

            &:first-child {
              border-left: 1px solid #878787;
            }
          }
        }

        .language {
          display: flex;

          li {
            border-right: 2px solid #bdbdbd;
            height: 20px;
            position: relative;
            &:first-child {
              border-left: 2px solid #bdbdbd;
            }

            button {
              background: transparent;
              cursor: pointer;
              color: #717171;
              width: 100%;
              padding: 0 10px;
              height: 100%;
            }
          }

          .selected {
            background: #f2f2f2;
          }
        }
      }

      .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 35px;
        z-index: 100;

        li {
          text-align: center;

          height: 60px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-right: 2px solid $--light-green;
          position: relative;

          &:first-child {
            border-left: 2px solid $--light-green;
          }

          a {
            text-align: center;
            font-size: 17px;
            color: $--dark-blue;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;

            &::before {
              content: '';
              width: 100px;
              height: 100px;
              position: absolute;
              bottom: -10px;
              right: 0;
            }
          }

          .subMenu {
            position: absolute;
            top: 62px;
            left: 0;
            width: 260px;
            display: none;
            flex-direction: column;
            gap: 2px;
            &:hover {
              display: flex;
            }

            &::before {
              content: '';
              width: 100%;
              height: 200px;
              position: absolute;
              bottom: -200px;
              right: 0;
            }

            .subMenuWrapper {
              height: 25px;
              text-align: left;
              background: #e4f2f0;
              display: flex;
              align-items: center;
              a {
                height: 100%;
                color: $--dark-blue;
                font-size: 13px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding-left: 25px;
              }
            }

            .selected {
              background: #c7efe8;
            }
          }

          .subInnerWrapper {
            top: 62px;
            left: 25px;
            padding-left: 25px;
            width: 260px;
            display: none;
            flex-direction: column;
            gap: 2px;

            &:hover {
              display: flex;
            }

            .subInner {
              height: 25px;
              text-align: left;
              background: #e4f2f0;
              display: flex;
              align-items: center;
              a {
                height: 100%;
                color: $--dark-blue;
                font-size: 13px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding-left: 25px;
              }
            }

            .selected {
              background: #c7efe8;
            }
          }

          &:hover,
          & > a:hover {
            .subMenu {
              display: flex;
            }
          }
        }

        .canHover:hover ~ .subInnerWrapper {
          display: flex;
        }

        .selected {
          background: #e4f2f0;

          a {
            font-weight: 700;
          }
        }
      }
    }
  }
}

.arbHeader {
  .wrapper {
    .contentWrapper {
      .top {
        .socialMedia {
          a {
            border-right: 1px solid #bdbdbd;

            &:last-child {
              border-left: 1px solid #878787;
            }

            &:first-child {
              border-left: unset;
            }
          }
        }

        .language {
          display: flex;

          li {
            &:first-child {
              border-left: unset;
            }

            &:last-child {
              border-left: 2px solid #bdbdbd;
            }
          }
        }
      }

      .bottom {
        li {
          text-align: center;

          height: 60px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-right: 2px solid $--light-green;
          position: relative;

          &:first-child {
            border-left: unset;
          }

          &:last-child {
            border-left: 2px solid $--light-green;
          }
        }
      }
    }
  }
}

.mobileMenu,
.hamburgerMenu {
  display: none !important;
}

@media only screen and (max-width: 900px) {
  .header {
    .wrapper {
      width: 90%;

      .logo {
        height: 90px;
      }
      .contentWrapper {
        display: none;
      }

      .hamburgerMenu {
        background: transparent;
        display: flex !important;

        svg {
          path {
            fill: $--light-green;
          }
        }
      }

      .mobileMenu {
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        background: white;
        width: 100%;
        height: 100%;
        padding: 25px;
        box-sizing: border-box;
        display: flex !important;
        flex-direction: column;

        .close {
          position: absolute;
          right: 20px;
          top: 50px;
          background: transparent;

          svg {
            path {
              fill: #426586;
            }
          }
        }

        .menu {
          display: flex;
          flex-direction: column;
          gap: 3px;
          padding-top: 80px;
          li {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            a {
              font-size: 16px;
              color: #426586;
              width: 100%;
              height: 100%;
              display: flex;
              background: #e4f2f0;
              padding: 7px 30px;
              box-sizing: border-box;
            }

            .subMenu {
              display: flex;
              flex-direction: column;
              gap: 3px;
              margin-top: 3px;
              padding-left: 30px;
              width: 100%;
              box-sizing: border-box;
              a {
                font-size: 16px;
                width: 100% !important;
              }

              .subInnerWrapper {
                display: flex;
                flex-direction: column;
                gap: 3px;
                padding-left: 30px;
              }
            }
          }

          .selected {
            .head {
              background: #c7efe8;
              font-weight: 700;
            }
          }
        }

        .selectedBottom {
          background: #c7efe8;
          box-sizing: border-box;
          width: 100%;

          a {
            background: #c7efe8 !important;
            font-weight: 700;
          }
        }

        .bottom {
          .socialMedia {
            display: flex;
            gap: 10px;
            margin-top: 50px;

            a {
              width: 35px;
              height: 35px;

              svg {
                width: 100%;
                height: 100%;

                path {
                  fill: #426586;
                }
              }
            }
          }
          .language {
            display: flex;
            margin-top: 20px;

            li {
              border-right: 2px solid #426586;
              height: 20px;
              position: relative;
              &:first-child {
                border-left: 2px solid #426586;
              }

              button {
                background: transparent;
                cursor: pointer;
                color: #426586;
                width: 100%;
                padding: 0 10px;
                height: 100%;
                font-size: 18px;
              }
            }

            .selected {
              background: #c7efe8;

              button {
                color: #426586;
              }
            }
          }
        }
      }

      .arb {
        .close {
          left: 20px;
          right: unset;
        }
      }
    }
  }
}
