@import '../../assets/style/variables.module.scss';

.operasyonSonrasiTalimatlar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper {
    width: 100%;
    max-width: $--max-width;
    display: flex;
    flex-direction: column;
    align-items: center;

    .headCard {
      background-repeat: no-repeat;
      background-position: right;
      background-size: 250px;
      padding-top: 35px;
      width: 100%;
      box-sizing: border-box;
      margin-bottom: 50px;
      text-align: center;
      h1 {
        font-size: 60px;
        line-height: 70px;
        color: $--dark-blue;
      }
    }

    h2 {
      font-size: 22px;
      font-weight: 500;
      color: $--dark-blue;
      display: flex;
      width: 100%;
      align-items: flex-start;
      margin-top: 20px;
      font-weight: 700;
    }

    .listWrapper {
      width: 97%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-top: 30px;

      ul {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        list-style: disc;

        li {
          line-height: 30px;
          font-size: 19px;
          text-align: justify;
          hyphens: auto;
          color: $--dark-blue;
        }
      }
    }

    .imageCard {
      width: 100%;
      margin-top: 30px;
      height: 500px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }
  }
}

@media only screen and (max-width: 1081px) {
  .operasyonSonrasiTalimatlar {
    .wrapper {
      .headCard {
        padding: 50px;
        height: 100%;
        background-position: top;
        background-size: 100px;
        margin-bottom: unset;
        h1 {
          font-size: 42px;
          text-align: center;
          line-height: 45px;
        }
      }

      h2 {
        text-align: center;
        font-size: 18px;
        align-items: center;
        line-height: 26px;
      }

      .listWrapper {
        flex-direction: column;
        width: 80%;

        ul {
          width: 100%;

          li {
            hyphens: auto;
            font-size: 17px;
            line-height: 28px;
          }
        }
      }

      .imageCard {
        h2 {
          font-size: 34px;
          text-align: center;
        }
      }
    }
  }
}
