@import '../../assets/style/variables.module.scss';

.clinic {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper {
    width: 100%;
    max-width: $--max-width;
    display: flex;
    flex-direction: column;
    gap: 30px;

    img {
      width: 100%;
      height: 700px;
      object-fit: cover;
      object-position: center;
    }
  }
}

@media only screen and (max-width: 900px) {
  .clinic {
    .wrapper {
      img {
        height: 300px;
      }
    }
  }
}
