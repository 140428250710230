@import '../../assets/style/variables.module.scss';

.onlineReservation {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 100px;

  .wrapper {
    width: 100%;
    max-width: $--max-width;
    display: flex;
    flex-direction: column;
    align-items: center;

    .imageCard {
      width: 100%;
      margin-top: 30px;
      height: 500px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;

      h2 {
        font-size: 30px;
        font-weight: 700;
        color: $--dark-blue;
        background: #d0f4ee;
        padding: 15px 20px;
        min-width: 560px;
        text-align: center;
      }
    }

    .subInfo {
      width: 100%;
      font-size: 19px;
      line-height: 27px;
      text-align: center;
      padding: 20px 50px;
      box-sizing: border-box;
      color: $--dark-blue;
      background: #ebfcf9;
      font-weight: 600;
    }

    .infoCard {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30px;
      h2 {
        font-size: 30px;
        font-weight: 700;
        color: $--dark-blue;
        background: #d0f4ee;
        padding: 15px 20px;
        min-width: 560px;
        text-align: center;
      }

      ul {
        border: 1px solid #d0f4ee;
        padding: 40px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 30px;

        li {
          font-size: 19px;
          color: $--dark-blue;
          line-height: 27px;
        }
      }
    }

    .formWrapper {
      width: 100%;
      border: 1px solid #d0f4ee;
      margin-top: 20px;

      .form {
        width: 100%;
        padding: 10px;
        box-sizing: border-box;

        .row {
          width: 100%;
          display: flex;
          justify-content: space-between;
          gap: 20px;
          margin-bottom: 20px;
        }

        .input {
          display: flex;
          flex-direction: column;
          width: 33%;

          h5 {
            background: #d0f4ee;
            color: $--dark-blue;
            padding: 5px 10px;
            width: 100%;
            box-sizing: border-box;
            font-weight: 500;
            line-height: 27px;
            font-size: 19px;
          }
          input {
            width: 100%;
            border: 1px solid #d0f4ee;
            box-sizing: border-box;
            margin-top: 10px;
            height: 45px;
            padding-left: 10px;
            line-height: 27px;
            font-size: 19px;
          }
        }

        .radioColumn {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          h5 {
            background: #d0f4ee;
            color: $--dark-blue;
            padding: 5px 10px;
            min-width: 32%;
            box-sizing: border-box;
            font-weight: 500;
            line-height: 27px;
            font-size: 19px;
          }

          .radioWrapper {
            margin-top: 20px;
            width: 100%;
          }

          .radio {
            background: #ebfcf9;
            width: 100%;
            height: 35px;
            display: flex;
            align-items: center;
            color: $--dark-blue;
            position: relative;
            cursor: pointer;
            box-sizing: border-box;
            padding-left: 10px;
            line-height: 27px;
            font-size: 19px;

            span {
              font-size: 12px;
              margin-left: 7px;
            }

            input {
              display: none;
            }

            &::before {
              content: '';
              width: 20px;
              height: 20px;
              background: #d0f4ee;
              position: absolute;
              right: 10px;
            }
          }

          .radioSelected {
            border: 1px solid $--light-green;
            &::before {
              content: '';
              background: $--light-green;
            }
          }
        }

        .textareaColumn {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 20px;
          h5 {
            background: #d0f4ee;
            color: $--dark-blue;
            padding: 5px 10px;
            box-sizing: border-box;
            font-weight: 500;
            line-height: 27px;
            font-size: 19px;
          }

          .textArea {
            width: 100%;
            height: 110px;
            border: 1px solid #d0f4ee;
            margin-top: 10px;

            textarea {
              width: 100%;
              height: 100%;
              resize: none;
              padding-left: 15px;
              padding-top: 15px;
              box-sizing: border-box;
              line-height: 27px;
              font-size: 19px;
            }
          }
        }

        .rowEnd {
          justify-content: flex-end;

          .radio {
            width: 32.2%;
            box-sizing: border-box;
          }
        }

        .info {
          width: 100%;
          height: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #d0f4ee;
          color: $--dark-blue;
          font-size: 19px;
          margin-bottom: 20px;
        }

        .photoColumn {
          margin-bottom: 20px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          h5 {
            background: #d0f4ee;
            color: $--dark-blue;
            padding: 5px 10px;
            min-width: 32%;
            box-sizing: border-box;
            font-weight: 500;
            line-height: 27px;
            font-size: 19px;
          }

          .examplePhoto {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 30px;
            box-sizing: border-box;
            margin-top: 20px;

            img {
              width: 33%;
              box-sizing: border-box;
              height: 346px;
              object-fit: cover;
              object-position: center;
            }
          }

          .photoWrapper {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            gap: 30px;

            .photo {
              width: 33%;
              background: #ebfcf9;
              height: 346px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
              }

              input {
                visibility: hidden;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              }
            }

            .uploaded {
              border: 1px solid $--light-green;
            }
          }
        }
        .submitWrapper {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-top: 50px;

          .checkbox {
            width: 100%;

            span {
              font-size: 19px;
              color: $--dark-blue;
              margin-left: 10px;
            }
          }

          .submit {
            padding: 15px 30px;
            background: $--light-green;
            width: 50%;
            cursor: pointer;
            color: white;
            margin-top: 10px;
          }
        }
      }
    }
  }
}

.arb {
  .radio {
    &::before {
      content: '';

      right: unset !important;
      left: 10px !important;
    }
  }
}

.error {
  margin-top: 5px;
  color: rgb(238, 98, 98);
}

@media only screen and (max-width: 900px) {
  .onlineReservation {
    .wrapper {
      .imageCard {
        width: 90%;
        background-size: cover;
        background-position: center;
        h2 {
          min-width: unset;
          width: 100%;
        }
      }
      .infoCard {
        width: 90%;
        h2 {
          min-width: unset;
          width: 90%;
        }
      }

      .formWrapper {
        width: 90%;
        .form {
          .row {
            flex-direction: column;

            .input {
              width: 100%;
            }
          }

          .radioColumn {
            .radio {
              width: 100%;
            }
          }

          .photoColumn {
            .examplePhoto {
              flex-direction: column;

              img {
                width: 100%;
              }
            }
            .photoWrapper {
              flex-direction: column;

              .photo {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
