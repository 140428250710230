@import '../../assets/style/variables.module.scss';

.popup {
  min-width: 500px;
  height: 400px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 901;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
  .wrapper {
    display: flex;
    flex-direction: column;
    width: 80%;

    h1 {
      font-size: 24px;
      font-weight: 400;
      text-align: center;
      line-height: 34px;
      color: $--dark-blue;
    }

    button {
      height: 45px;
      color: white;
      background: $--light-green;
      cursor: pointer;
      margin-top: 50px;
    }
  }
}

.shadowBox {
  width: 100vw;
  height: 100vh;
  background: rgba($color: $--light-green, $alpha: 0.4);
  position: fixed;
  z-index: 900;
  top: 0px;
}

$spCol: $--light-green;

.spSlices {
  width: 32px;
  height: 32px;
  clear: both;
  margin: 20px auto;
  border-radius: 50%;
  border-top: 16px rgba($spCol, 0.75) solid;
  border-left: 16px rgba($spCol, 0.25) solid;
  border-bottom: 16px rgba($spCol, 0.25) solid;
  border-right: 16px rgba($spCol, 0.25) solid;
  -webkit-animation: spSlices 1s infinite linear;
  animation: spSlices 1s infinite linear;
}
@-webkit-keyframes spSlices {
  0% {
    border-top: 16px rgba($spCol, 0.75) solid;
    border-right: 16px rgba($spCol, 0.25) solid;
    border-bottom: 16px rgba($spCol, 0.25) solid;
    border-left: 16px rgba($spCol, 0.25) solid;
  }
  25% {
    border-top: 16px rgba($spCol, 0.25) solid;
    border-right: 16px rgba($spCol, 0.75) solid;
    border-bottom: 16px rgba($spCol, 0.25) solid;
    border-left: 16px rgba($spCol, 0.25) solid;
  }
  50% {
    border-top: 16px rgba($spCol, 0.25) solid;
    border-right: 16px rgba($spCol, 0.25) solid;
    border-bottom: 16px rgba($spCol, 0.75) solid;
    border-left: 16px rgba($spCol, 0.25) solid;
  }
  75% {
    border-top: 16px rgba($spCol, 0.25) solid;
    border-right: 16px rgba($spCol, 0.25) solid;
    border-bottom: 16px rgba($spCol, 0.25) solid;
    border-left: 16px rgba($spCol, 0.75) solid;
  }
  100% {
    border-top: 16px rgba($spCol, 0.75) solid;
    border-right: 16px rgba($spCol, 0.25) solid;
    border-bottom: 16px rgba($spCol, 0.25) solid;
    border-left: 16px rgba($spCol, 0.25) solid;
  }
}
@keyframes spSlices {
  0% {
    border-top: 16px rgba($spCol, 0.75) solid;
    border-right: 16px rgba($spCol, 0.25) solid;
    border-bottom: 16px rgba($spCol, 0.25) solid;
    border-left: 16px rgba($spCol, 0.25) solid;
  }
  25% {
    border-top: 16px rgba($spCol, 0.25) solid;
    border-right: 16px rgba($spCol, 0.75) solid;
    border-bottom: 16px rgba($spCol, 0.25) solid;
    border-left: 16px rgba($spCol, 0.25) solid;
  }
  50% {
    border-top: 16px rgba($spCol, 0.25) solid;
    border-right: 16px rgba($spCol, 0.25) solid;
    border-bottom: 16px rgba($spCol, 0.75) solid;
    border-left: 16px rgba($spCol, 0.25) solid;
  }
  75% {
    border-top: 16px rgba($spCol, 0.25) solid;
    border-right: 16px rgba($spCol, 0.25) solid;
    border-bottom: 16px rgba($spCol, 0.25) solid;
    border-left: 16px rgba($spCol, 0.75) solid;
  }
  100% {
    border-top: 16px rgba($spCol, 0.75) solid;
    border-right: 16px rgba($spCol, 0.25) solid;
    border-bottom: 16px rgba($spCol, 0.25) solid;
    border-left: 16px rgba($spCol, 0.25) solid;
  }
}
