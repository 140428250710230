@import '../../assets/style/variables.module.scss';

.sacDokulmesiNedenleri {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper {
    width: 100%;
    max-width: $--max-width;
    display: flex;
    flex-direction: column;
    align-items: center;

    .headCard {
      height: 660px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      padding-left: 50px;
      padding-top: 35px;
      width: 100%;
      box-sizing: border-box;

      h1 {
        font-size: 60px;
        line-height: 70px;
        color: $--dark-blue;
      }
    }

    .paragraphWrapper {
      background: #ebfcf9;
      padding: 20px;
      box-sizing: border-box;
      width: 100%;
      .paragraph {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 35px;

        .column {
          width: 100%;

          p,
          h2 {
            color: $--dark-blue;
          }

          h2 {
            margin-bottom: 20px;
            font-weight: 700;
            font-size: 19.5px;
          }

          span {
            font-style: italic;
          }

          p {
            line-height: 30px;
            font-size: 19.5px;
            text-align: justify;
            hyphens: auto;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .sacDokulmesiNedenleri {
    .wrapper {
      .headCard {
        padding: 50px;
        height: 620px;
        background-position: center 80px;
        background-color: #f1f1f1;

        h1 {
          font-size: 42px;
          line-height: 45px;
          text-align: center;
        }
      }

      .paragraphWrapper {
        padding: 20px 20px;
        width: 90%;

        .paragraph {
          flex-direction: column;
          width: 100%;

          .column {
            width: 100%;
          }
        }
      }
    }
  }
}
