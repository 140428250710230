@import '../../assets/style/variables.module.scss';

.home {
  width: 100%;
  flex-direction: column;
  max-width: $--max-width;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  h2 {
    font-size: 40px;
    text-align: center;
    margin: 50px 0;
    color: $--dark-blue;
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 50px;

    .column {
      width: 100%;

      .title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 50px;
      }

      a {
        width: 50%;
        font-size: 40px;
        padding: 30px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $--light-blue;
        font-weight: 600;
        color: $--dark-blue;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        margin-top: 45px;
        max-height: 300px;
      }

      p {
        line-height: 27px;
        font-size: 19px;
        color: $--dark-blue;
        margin-left: 45px;
        margin-top: 45px;
      }
    }
  }
}

.headInfo {
  min-height: 700px;
  width: 100%;
  display: flex;
  padding: 45px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: white;
  h1 {
    font-size: 60px;
    line-height: 70px;
    max-width: 488px;
    color: #00b2aa;
  }

  p {
    line-height: 27px;
    font-size: 19px;
    margin-top: 45px;
    text-align: justify;
    hyphens: auto;
    max-width: 488px;
  }

  img {
    max-width: 530px;
    object-fit: cover;
    object-position: center;
    margin-left: 70px;
  }
}

@media only screen and (max-width: 900px) {
  .home {
    .headInfo {
      flex-direction: column;
      height: 100%;
      background-size: cover;
      background-position: center;
      box-sizing: border-box;

      img {
        width: 100%;
        max-width: unset;
        margin-left: unset;
        margin-top: 20px;
      }
    }

    .content {
      flex-direction: column;
      align-items: center;

      .column {
        width: 90%;

        .title {
          flex-direction: column;
          gap: 20px;
        }

        a {
          text-align: center;
          width: 100%;
        }

        p {
          margin: unset;
          margin-top: 30px;
        }
      }
    }
  }
}
