@import '../../assets/style/variables.module.scss';

.sakalEkimi {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper {
    width: 100%;
    max-width: $--max-width;

    .card {
      width: 100%;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center -20px;
      min-height: 500px;
      box-sizing: border-box;
      position: relative;

      h1 {
        font-size: 60px;
        font-weight: 700;
        color: white;
        position: absolute;
        top: 60px;
        left: 60px;
      }

      img {
        width: 100%;
        min-height: 500px;
        max-height: 700px;

        object-fit: cover;
        object-position: top;
      }

      p {
        bottom: 0;
        z-index: 20;
        font-size: 19px;
        line-height: 27px;
        background-color: #ebfcf9;
        padding: 20px;
        box-sizing: border-box;
        font-weight: 500;
        color: $--dark-blue;
      }
      .space {
        margin: 20px 0;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .sakalEkimi {
    overflow-x: hidden;

    .wrapper {
      .card {
        padding: 0;
        background-size: cover;
        background-position: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
          width: 100%;
          min-height: unset;

          object-fit: cover;
        }

        h1 {
          font-size: 42px;
          text-align: center;
          top: 180px;
          color: $--dark-blue;
        }

        p {
          left: unset;
          position: relative;
          width: 90%;
        }
      }
    }
  }
}
