@import '../../assets/style/variables.module.scss';

.contact {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .wrapper {
    width: 100%;
    max-width: $--max-width;

    img {
      width: 100%;
      height: 630px;
      object-fit: cover;
      object-position: center;
    }

    .iframeWrapper {
      width: 100%;
      height: 500px;

      iframe {
        width: 100%;
        height: 100%;
      }
    }

    .cardWrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 30px;
      margin-top: 50px;

      .card {
        width: 100%;

        h4 {
          background: #d0f4ee;
          height: 36px;
          display: flex;
          align-items: center;
          font-size: 19px;
          color: $--dark-blue;
          font-weight: 600;
          padding-left: 15px;
          padding-right: 15px;
          box-sizing: border-box;
        }

        p {
          min-height: 137px;
          border: 2px solid #d0f4ee;
          margin-top: 10px;
          padding: 20px 17px;
          box-sizing: border-box;
          line-height: 25px;
          color: $--dark-blue;
          font-size: 19px;
        }
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .contact {
    .wrapper {
      width: 90%;
      img {
        height: 250px;
      }
      .cardWrapper {
        flex-direction: column;
      }
    }
  }
}
